import styled from 'styled-components';

export const FooterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2.5rem;
    margin-top: 10vh;
    padding:20px;
    position: static;
    left: 0;
    bottom: 0;
`;

export const FooterText = styled.h4`
    
`;